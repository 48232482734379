import * as constant from './actionTypes'
import API from 'utils/API'

export function getAlertsRequest() {
  return {
    type: constant.GET_ALERTS_REQUEST
  }
}

export function getAlertsSuccess({ payload }) {
  return {
    type: constant.GET_ALERTS_SUCCESS,
    payload
  }
}

export function getAlertsFail({ error }) {
  return {
    type: constant.GET_ALERTS_FAILURE,
    payload: error
  }
}

export function createAlertRequest() {
  return {
    type: constant.CREATE_ALERT_REQUEST
  }
}

export function createAlertSuccess({ payload }) {
  return {
    type: constant.CREATE_ALERT_SUCCESS,
    payload
  }
}

export function createAlertFail({ error }) {
  return {
    type: constant.CREATE_ALERT_FAILURE,
    payload: error
  }
}

export function updateAlertRequest() {
  return {
    type: constant.UPDATE_ALERT_REQUEST
  }
}

export function updateAlertSuccess({ payload }) {
  return {
    type: constant.UPDATE_ALERT_SUCCESS,
    payload
  }
}

export function updateAlertFail({ error }) {
  return {
    type: constant.UPDATE_ALERT_FAILURE,
    payload: error
  }
}

export function deleteAlertRequest() {
  return {
    type: constant.DELETE_ALERT_REQUEST
  }
}

export function deleteAlertSuccess({ payload }) {
  return {
    type: constant.DELETE_ALERT_SUCCESS,
    payload
  }
}

export function deleteAlertFail({ error }) {
  return {
    type: constant.DELETE_ALERT_FAILURE,
    payload: error
  }
}

export function getAlertKpiRequest() {
  return {
    type: constant.GET_ALERT_KPI_REQUEST
  }
}


export function checkEANSRequest() {
  return {
    type: constant.CHECK_EANS_REQUEST
  }
}

export function checkEANSSuccess({ payload }) {
  return {
    type: constant.CHECK_EANS_SUCCESS,
    payload
  }
}

export function checkEANSFail({ error }) {
  return {
    type: constant.CHECK_EANS_FAILURE,
    payload: error
  }
}


export function getUsersRequest() {
  return {
    type: constant.GET_ALERT_USERS_REQUEST
  }
}

export function getUsersSuccess({ payload }) {
  return {
    type: constant.GET_ALERT_USERS_SUCCESS,
    payload
  }
}

export function getUsersFail({ error }) {
  return {
    type: constant.GET_ALERT_USERS_FAILURE,
    payload: error
  }
}

export function getAlertKpiSuccess({ payload }) {
  return {
    type: constant.GET_ALERT_KPI_SUCCESS,
    payload
  }
}

export function getAlertKpiFail({ error }) {
  return {
    type: constant.GET_ALERT_KPI_FAILURE,
    payload: error
  }
}

export const getAlerts = () => {
  return (dispatch) => {
    dispatch(getAlertsRequest())
    return API({
      url: `/api/alerts/list/`,
      useHeader: true,
      useToken: false
    })
      .then(data => dispatch(getAlertsSuccess({ payload: data })))
      .catch(err => dispatch(getAlertsFail({ error: err })))
  }
}

export const getAlertKpi = () => {
  return (dispatch) => {
    dispatch(getAlertKpiRequest())
    return API({
      url: `/api/alerts/alert_kpi/`,
      useHeader: true,
      useToken: false
    })
      .then(data => dispatch(getAlertKpiSuccess({ payload: data })))
      .catch(err => dispatch(getAlertKpiFail({ error: err })))
  }
}

export const createAlert = (data) => {
  return (dispatch) => {
    dispatch(createAlertRequest())
    return API({
      url: `/api/alerts/alert/`,
      method: 'POST',
      data,
      useHeader: true,
      useToken: false
    })
      .then(data => dispatch(createAlertSuccess({ payload: data })))
      .catch(err => dispatch(createAlertFail({ error: err })))
  }
}

export const updateAlert = (id, data) => {
  return (dispatch) => {
    dispatch(updateAlertRequest())
    return API({
      url: `/api/alerts/alert/${id}/`,
      method: 'PUT',
      data,
      useHeader: true,
      useToken: false
    })
      .then(data => dispatch(updateAlertSuccess({ payload: data })))
      .catch(err => dispatch(updateAlertFail({ error: err })))
  }
}

export const deleteAlert = (id) => {
  return (dispatch) => {
    dispatch(deleteAlertRequest())
    return API({
      url: `/api/alerts/alert/${id}/`,
      method: 'DELETE',
      useHeader: true,
      useToken: false
    })
      .then(() => dispatch(deleteAlertSuccess({ payload: { id } })))
      .catch(err => dispatch(deleteAlertFail({ error: err })))
  }
}

export const getUsers = () => {
  return (dispatch) => {
    dispatch(getUsersRequest())
    return API({
      url: `/api/users/`,
      useHeader: true,
      useToken: false
    })
      .then(data => dispatch(getUsersSuccess({ payload: data })))
      .catch(err => dispatch(getUsersFail({ error: err })))
  }
}


export const checkEANS = (eans) => {
  return (dispatch) => {
    dispatch(checkEANSRequest())
    return API({
      method: 'POST',
      data: { eans },
      url: `/api/alerts/check-eans/`,
      useHeader: true,
      useToken: false
    })
      .then(data => dispatch(checkEANSSuccess({ payload: data })))
      .catch(err => dispatch(checkEANSFail({ error: err })))
  }
}

export const getNewNotification = (notification) => {
  return (dispatch) => {
    dispatch({
      type: constant.GET_NEW_NOTIFICATION,
      payload: notification
    })
  }
}


export function getNotificatoinsRequest() {
  return {
    type: constant.GET_NOTIFICATION_REQUEST
  }
}

export function getNotificatoinsSuccess({ payload }) {
  return {
    type: constant.GET_NOTIFICATION_SUCCESS,
    payload
  }
}

export function getNotificatoinsFail({ error }) {
  return {
    type: constant.GET_NOTIFICATION_FAILURE,
    payload: error
  }
}

export const getNotifications = () => {
  return (dispatch) => {
    dispatch(getNotificatoinsRequest())
    return API({
      url: `/api/notifications/list/`,
      useHeader: true,
      useToken: false
    })
      .then(data => dispatch(getNotificatoinsSuccess({ payload: data })))
      .catch(err => dispatch(getNotificatoinsFail({ error: err })))
  }
}

export function setNotificationReadRequest() {
  return {
    type: constant.SET_NOTIFICATION_READ_REQUEST
  }
}

export function setNotificationReadSuccess({ payload }) {
  return {
    type: constant.SET_NOTIFICATION_READ_SUCCESS,
    payload
  }
}

export function setNotificationReadFail({ error }) {
  return {
    type: constant.SET_NOTIFICATION_READ_FAILURE,
    payload: error
  }
}

// ex: data = [
//   {"id":2, "acknowledge":false},
//   {"id":3, "acknowledge":false}
// ]
export const setNotificationRead = (data) => {
  return (dispatch) => {
    dispatch(setNotificationReadRequest())
    return API({
      url: `/api/notifications/acknowledge_multiple/`,
      method: 'POST',
      data,
      useHeader: true,
      useToken: false
    })
      .then(() => dispatch(setNotificationReadSuccess({ payload: data })))
      .catch(err => dispatch(setNotificationReadFail({ error: err })))
  }
}

export const resetNotificationsCount = () => {
  return (dispatch) => {
    dispatch({
      type: constant.RESET_NOTIFICATION_COUNT
    })
  }
}
