import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { VariableSizeList } from "react-window";
import PropTypes from "prop-types";
import ListSubheader from "@mui/material/ListSubheader";
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from '@mui/material/Autocomplete';
import { treeToArray } from "utils/searchFiltersInTree";

const SEPARATORS = /[\t ,]+/;  // Tab, space, and comma

const useStyles = makeStyles()(() => ({
  autocomplete: {
    marginBlock: "8px",
  },
  fieldset: {
    borderRadius: "4px",
  },
  legend: {
    padding: "4px",
  },
  chip: {
    margin: "2px",
  },
  labelChip: {
    marginLeft: "8px",
  },
}));
const groupColors = [
  "#d1c4e9",
  "#80cbc4",
  "#512da8",
  "#c5e1a5",
  "#aaaaaa",
  "#9ccc65",
  "#b39ddb",
];
const groups = [
  {
    type: "store",
    verbose: "Magasins",
    color: "#d1c4e9",
  },
  {
    type: "category",
    verbose: "CatÃ©gories",
    color: "#80cbc4",
  },
  {
    type: "warehouse",
    verbose: "Entrepots",
    color: "#512da8",
  },
  {
    type: "manufacturer",
    verbose: "Industriels",
    color: "#c5e1a5",
  },
  {
    type: "ean",
    verbose: "EAN",
    color: "#aaaaaa",
  },
  {
    type: "brand",
    verbose: "Marques",
    color: "#9ccc65",
  },
  {
    type: "retailer",
    verbose: "Distributeurs",
    color: "#b39ddb",
  },
];

const AlertFilter = ({ profile, selectedFilters, setSelectedFilters, selectedEANS, setSelectedEANS, invalidEANS }) => {
  const { classes } = useStyles();
  const [filterOptions, setFilterOptions] = useState([]);
  
  useEffect(() => {
    if (!filterOptions.length && profile.base_filters) {
      let suggestedFilters = [];
      profile.base_filters.map((item) => {
        if (item.type === "tree") {
          suggestedFilters.push(
            ...treeToArray(item.values).map((v) => ({
              ...v,
              key: item.name,
              verbose: item.verbose,
            }))
          );
        } else {
          suggestedFilters.push(
            ...item.values.map((v) => ({
              ...v,
              key: item.name,
              verbose: item.verbose,
            }))
          );
        }
      });
      setFilterOptions(suggestedFilters);
    }
  }, [profile]);


  const handleDeleteFilter = (id) => (event) => {
    event.preventDefault();
    setSelectedFilters([...selectedFilters.filter((f) => f.id !== id)]);
  };

  const getOptionLabelOld = (option) => {
    const selectedOption = groups.find((g) => g.type === option.name);
    const color = selectedOption ?? "";
    return (
      <>
        <span>{option.title}</span>
        <Chip
          size="small"
          className={classes.labelChip}
          style={{ backgroundColor: color }}
          label={option.key}
        />
      </>
    );
  };

  const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];
  const LISTBOX_PADDING = 8; // px

  function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
      style: {
        ...style,
        top: style.top + LISTBOX_PADDING,
      },
    });
  }

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }
  // Adapter for react-window
  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
  ) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  ListboxComponent.propTypes = {
    children: PropTypes.node,
  };

  const handleSelectFilter = (event, newValue) => {
    const newOptions = newValue.flatMap(value => {
      if (typeof value === 'string') {
        return value.split(SEPARATORS).map(item => item.trim()).filter(item => item !== '');
      } else {
        return [value];
      }
    });
    const uniqueOptions = [...new Set(newOptions)];
    setSelectedEANS(uniqueOptions);
  };

  const getHelperText = () => {
    if (invalidEANS.length > 0) {
      return "Veuillez corriger les EANs invalides";
    }
    return "Séparation acceptée sont virgule, espace ou tabulation";
  }


  const getAutocompleteInput = () => (
    <Autocomplete
      freeSolo
      multiple
      clearOnEscape
      disableClearable
      renderTags={getRenderTags()}
      options={selectedEANS}
      onChange={handleSelectFilter}
      value={selectedEANS}
      noOptionsText={"Pas d'options..."}
      className={classes.autocomplete}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Ajoutez vos EANs"
          variant="outlined"
          placeholder="8000625087402,3234640034317"
          error={invalidEANS.length > 0}
          helperText={getHelperText} 
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );

  const getRenderTags = () => (value, getTagProps) => {
    return  value.map((option, index) => {
      if (invalidEANS.length > 0 && invalidEANS.includes(option)){
        return (
          <Chip
            key={index}
            label={option}
            {...getTagProps({ index })}
            style={{ backgroundColor: "red" }}
          />
        );
      }
      return (
        <Chip
          key={index}
          label={option}
          {...getTagProps({ index })}
        />
      );

    })
  };

  return (
    <>
      {getAutocompleteInput()}
    </>
  );
};

export default AlertFilter;
