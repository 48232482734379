import React from "react";
import { TextField, Chip, Box, Grid, MenuItem } from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import InputAdornment from '@mui/material/InputAdornment';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import DateFnsUtils from "@date-io/date-fns";

const freqOptions = [
  {
    label: "Jour",
    value: "day",
  },
  {
    label: "Semaine",
    value: "week",
  },
  {
    label: "Mois",
    value: "month",
  },
];

const AlertInfo = ({
  name,
  setName,
  kpi,
  setKpi,
  alertKpi,
  value,
  setValue,
  condition,
  setCondition,
  freq,
  setFreq,
  notificationTime,
  setNotificationTime,
  error,
  profile
}) => {
  const conditionOptions = [
    //{ key: "0", value: "Augmente €" },
    //{ key: "1", value: "Augmente %" },
    //{ key: "2", value: "Diminue €" },
    //{ key: "3", value: "Diminue %" },
    { key: 4, value: "Égale à" },
    { key: 5, value: "Différent de" },
    //{ key: "6", value: "Augmente" },
    //{ key: "7", value: "Diminue" },
    { key: 8, value: 'Supérieur à' },
    { key: 9, value: 'Inférieur à' },
    { key: 10, value: 'Percentage' },
  ]

  if (kpi) {
    const selectedKpi = alertKpi.find((ak) => ak.id === kpi);
    //conditionOptions = selectedKpi ? selectedKpi.conditions : [];
  }
  if (notificationTime) {
    setNotificationTime(notificationTime);
  }
  return (
    <LocalizationProvider utils={DateFnsUtils} dateAdapter={AdapterDateFns}>
      <Box mb={2}>
        <TextField
          label="Nom de l'alerte"
          name="nom"
          fullWidth
          value={name}
          error={error.name}
          onChange={(e) => setName(e.target.value)}
          style={{marginTop:'10%'}}
        />
      </Box>
      <h2 className="alert-row-title">Condition</h2>
      <Box my={2}>
        <TextField
          select
          fullWidth
          label="KPI"
          value={kpi}
          error={error.kpi}
          onChange={(e) => setKpi(e.target.value)}
        >
          {alertKpi.map((ak) => (
            <MenuItem key={ak.name} value={ak.id}>
              <Chip
                style={{ backgroundColor: `${ak.color}` }}
                color="primary"
                size="small"
                label={ak.name_localizations ? ak.name_localizations[profile.locale] : ak.name}
              />
              <span
                style={{
                  fontSize: "11px",
                  color: "#666666",
                  marginLeft: "15px",
                }}
              >
                {ak.description}
              </span>
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box my={1}>
        <TextField
          select
          fullWidth
          label="Condition"
          value={condition}
          error={error.condition}
          onChange={(e) => setCondition(e.target.value)}
        >
          {conditionOptions.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box my={1}>
        { condition === 10 ?
        <TextField
          label="Valeur"
          name="val"
          fullWidth
          identifire="alert-value"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          value={value}
          error={error.value}
          onChange={(e) => setValue(e.target.value)}/> :

        <TextField
          style={{marginBottom:'3%'}}
          label="Valeur"
          name="val"
          fullWidth
          identifire="alert-value"
          value={value}
          error={error.value}
          onChange={(e) => setValue(e.target.value)}
        />}
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <TextField
            select
            label={"M'alerter une fois par"}
            name="freq"
            fullWidth
            error={error.period}
            identifire="alert-period"
            value={freq}
            onChange={(e) => setFreq(e.target.value)}
          >
            {freqOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            label={"A l'heure"}
            ampm={false}
            fullWidth
            value={notificationTime}
            onChange={setNotificationTime}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default AlertInfo;
