import * as constant from './actionTypes'

const initialState = {
  fetching: false,
  alerts: [],
  alertKpi: [],
  users: [],
  notifications: [],
  notificationsCount: 0,
  error: '',
  checkedEANS: []
};


export default (state = initialState, action) => {
  switch (action.type) {

    case constant.CHECK_EANS_REQUEST: {
      return ({ ...state, fetching: true })
    }

    case constant.CHECK_EANS_SUCCESS: {
      return ({ ...state, fetching: false, checkedEANS: action.payload })
    }

    case constant.CHECK_EANS_FAILURE: {
      return ({ ...state, fetching: false, error: action.payload })
    }

    case constant.GET_ALERT_USERS_REQUEST: {
      return ({ ...state, fetching: true })
    }

    case constant.GET_ALERT_USERS_SUCCESS: {
      return ({ ...state, fetching: false, users: action.payload })
    }

    case constant.GET_ALERTS_REQUEST: {
      return ({ ...state, fetching: true })
    }

    case constant.GET_ALERTS_SUCCESS: {
      return ({ ...state, fetching: false, alerts: action.payload })
    }

    case constant.GET_ALERT_KPI_REQUEST: {
      return ({ ...state, fetching: true })
    }

    case constant.GET_ALERT_KPI_SUCCESS: {
      return ({ ...state, fetching: false, alertKpi: action.payload })
    }

    case constant.CREATE_ALERT_REQUEST: {
      return ({ ...state, fetching: true })
    }

    case constant.CREATE_ALERT_SUCCESS: {
      return ({ ...state, fetching: false, alerts: [...state.alerts, action.payload] })
    }

    case constant.UPDATE_ALERT_REQUEST: {
      return ({ ...state, fetching: true })
    }

    case constant.UPDATE_ALERT_SUCCESS: {
      const alerts = state.alerts.map(alert => {
        if (alert.id === action.payload.id) {
          return action.payload
        }
        return alert
      })
      return ({ ...state, fetching: false, alerts })
    }

    case constant.DELETE_ALERT_REQUEST: {
      return ({ ...state, fetching: true })
    }

    case constant.DELETE_ALERT_SUCCESS: {
      const alerts = state.alerts.filter(alert => {
        return alert.id !== action.payload.id
      })
      return ({ ...state, fetching: false, alerts })
    }

    case constant.GET_ALERTS_FAILURE:
    case constant.GET_ALERT_KPI_FAILURE:
    case constant.CREATE_ALERT_FAILURE:
    case constant.UPDATE_ALERT_FAILURE:
    case constant.DELETE_ALERT_FAILURE:
    case constant.GET_ALERT_USERS_FAILURE:
    case constant.GET_NOTIFICATION_FAILURE: {
      return ({ ...state, fetching: false, error: action.payload })
    }

    //Notifications
    case constant.GET_NOTIFICATION_SUCCESS: {
      const notifications = action.payload.results;
      const notificationsCount = notifications.filter(notif => !notif.acknowledged).length
      return ({ ...state, fetching: false, notifications, notificationsCount })
    }

    case constant.GET_NEW_NOTIFICATION: {
      return ({ ...state, notifications: [...action.payload, ...state.notifications], notificationsCount: state.notificationsCount + 1 })
    }

    case constant.SET_NOTIFICATION_READ_SUCCESS: {
      const notifications = state.notifications.map(notif => {
        if (!!action.payload.find(i => i.id === notif.id)) {
          return { ...notif, acknowledged: true }
        }
        return notif
      })

      return ({
        ...state,
        fetching: false,
        notifications,
      })
    }

    case constant.RESET_NOTIFICATION_COUNT: {
      return ({ ...state, notificationsCount: 0 })
    }

    default:
      return state
  }
}
